import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../App.css";

import FooterV2 from "../../components/Footer_V2/Footer";
import HeaderEmi from "../../components/Header_V2/HeaderEmi";
import SinglePositionComponent from "../../components/SinglePosition";
import BtnLoad from "../../components/Buttons/BtnLoad";

const config = require('../../config');
const vacanciesServer = config.params.VACANCIES;

const SinglePosition = (props) => {
  const { vacancyId } = useParams();
  const { vacancyListingDict, company, handleFacebookLink, handleWhatsappLink, singlePosition, queryParams, companyName } = props;
  
  const [state, setState] = useState({
    isLoading: false,
    generatingCode: false,
    listing: null,
    company: {},
    showReferral: false,
    modalOpened: false,
    applicationChannel: "",
    applyPosition: {},
    width: window.innerWidth,
  });

  const handleWindowSizeChange = () => {
    setState(prev => ({ ...prev, width: window.innerWidth }));
  };

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      
      try {
        const result = await fetch(`${vacanciesServer}/posting/${vacancyId}`);
        const position = await result.json();
        position.postingId = position.id;
        
        if (vacancyListingDict[position.postingId]) {
          position.id = vacancyListingDict[position.postingId].positionListingId;
          position.description = vacancyListingDict[position.postingId].positionDescription;
        }

        position.emiCode = "emipropuesta";
        
        let tagBrand = position.tags && position.tags.filter(tag => tag.name === "brand") || [];
        let brandsDict = tagBrand.length && tagBrand[0].value || '';
        let brands = brandsDict && brandsDict.map(brand => brand.slice(0,1) + brand.slice(1).toLowerCase()) || [];
        position.brand = brands.length && brands.join(", ") || '';

        setState(prev => ({
          ...prev,
          applyPosition: position
        }));
      } catch(e) {
        console.log(e);
      }
    };

    fetchData();
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [vacancyId, vacancyListingDict]);

  const openCloseModal = (channel) => {
    setState(prev => ({
      ...prev,
      modalOpened: !prev.modalOpened,
      applicationChannel: channel,
    }));
  };

  const {
    modalOpened,
    applicationChannel,
    applyPosition,
    width
  } = state;

  const isMobile = width <= 700;

  return (
    <>
      <HeaderEmi
        companyLogo={company.company_logo}
        singlePosition={singlePosition}
        companyName={companyName}
        query={queryParams}
        isMobile={isMobile}
        company={company}
        style={company.style || {}}
        linkGoBack={company.linkGoBack}
      />
      {vacancyListingDict[applyPosition.postingId] ? 
        <SinglePositionComponent 
          company={company} 
          applyPosition={{
            ...applyPosition, 
            description: vacancyListingDict[applyPosition.postingId].positionDescription, 
            id: vacancyListingDict[applyPosition.postingId].positionListingId
          }}
          openCloseModal={openCloseModal} 
          handleFacebookLink={handleFacebookLink}
          handleWhatsappLink={handleWhatsappLink} 
          applicationChannel={applicationChannel}
          modalOpened={modalOpened} 
          queryParams={queryParams} 
          style={company.style}
        /> : 
        <BtnLoad/>
      }
      <FooterV2 company={company} style={company.style} />
    </>
  );
};

export default SinglePosition;
