export const getAllQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  return params;
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch {
    return false;
  }
};

export const redirectToUrl = (url) => {
  if (!url) throw new Error('No URL provided');
  if (!isValidUrl(url)) throw new Error('Invalid URL provided', url);
  window.location.href = url;
};