import { useEffect } from 'react';
import { getAllQueryParams, redirectToUrl, isValidUrl } from '../utils/urlUtils';

const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Android/i.test(userAgent);
};

const formatSMSLink = (link) => {
  return isAndroid() ? link.replace('&', '?') : link;
};

const redirectToSMS = () => {
  const { r: link } = getAllQueryParams();

  if (link) {
    const formattedLink = formatSMSLink(link);
    if (isValidUrl(formattedLink)) redirectToUrl(formattedLink);;
  }
};

export const useSmsRedirect = () => {
  useEffect(() => {
    redirectToSMS();
  }, [])
}
