import { useEffect } from 'react';
import { getAllQueryParams, redirectToUrl } from '../utils/urlUtils';

export const useWhatsappRedirect = () => {
  useEffect(() => {
    const { to, e: message } = getAllQueryParams();

    if (to) {
      // Clean the phone number (remove spaces, dashes, etc)
      const cleanPhone = to.replace(/[^0-9]/g, '');

      // Encode the message to handle special characters properly
      const encodedMessage = message ? `?text=${encodeURIComponent(message)}` : '';

      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${cleanPhone}/${encodedMessage}`;

      // Redirect to WhatsApp
      redirectToUrl(whatsappUrl);
    }

  }, [])
}
